@import '../../../assets/styles/variables.scss';

.form-sublabel {
    font-size: 14px;
    line-height: 17px;
    color: $color-text;
    margin-top: 5px;
    margin-bottom: 5px;
}

.form-control-invalid {
    border-radius: 4px !important;
    border-color: #dc3545 !important;
}

.tags-valid {
    display: none;
}

.tags-invalid {
    display: inline;
    font-size: 12px;
    color: #dc3545;
}

.create-competence:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* CSS CHECKMARK TICK */
.checkmark {
    display:inline-block;
    width: 22px;
    height:22px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_stem {
    position: absolute;
    width:3px;
    height:9px;
    background-color:white;
    left:11px;
    top:6px;
}

.checkmark_kick {
    position: absolute;
    width:3px;
    height:3px;
    background-color:white;
    left:8px;
    top:12px;
}

.stem-inv {
    background-color: $color-headlines;
}

.kick-inv {
    background-color: $color-headlines;
}