.modal-invite-freelancers {
    .rodal-dialog {
        overflow-y: auto;
    }
}

.modal-invite-freelancers-title {
    color: purple;
    font-weight: 600;
}

.modal-invite-freelancers-task-container {
    transition: 0.2s all ease-in-out;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
}

.modal-invite-freelancers-task-checkbox {
    margin-top: auto;
    margin-bottom: auto; 
    padding-left: 25px;

    input {
        height: 20px;
        width: 20px;
    }
}

.modal-invite-freelancers-task-content {
    padding-left: 0px;
}

.modal-invite-freelancers-remove-freelancer {
    cursor: pointer;
    font-weight: 600;
    font-size: 0.9rem;
    padding: 2px 5px;
    background-color: rgba(0,0,0,.3);
}

.modal-invite-freelancers-tasks-col {
    border-right: 1px solid rgba(0,0,0,.1);
}

.modal-invite-freelancers-task-name {
    font-size: 1.05rem;
    font-weight: 600;
}

.modal-invite-freelancers-hr {
    margin: 0px;
}

.modal-invite-freelancers-task-info {
    padding-right: 0px;
}

.modal-invite-freelancers-task-more {
    transition: 0.2s all ease-in-out;

    span {
        cursor: pointer;
        margin-top: 15px;
        color: rgba(0, 0, 0, 0.65);
    }

    span:hover {
        color: rgba(0, 0, 0, 1.0);
    }
}