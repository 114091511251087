@import '../../assets/styles/variables.scss';

.new-competence-rodal {
    display: flex;
    align-items: center;
}

.modal-title {
    font-size: 18px;
    font-weight: bold;
    color: $color-headlines;
    padding-bottom: 8px;
    border-bottom: 1px solid $color-borders;
}

.c-slug {
    font-size: 14px;
    line-height: 30px;
    color: $color-placeholder;
}

.select-level {
    // min-width: 165px;
    width: 100%;
    // margin-right: 12px;
    margin-left: 12px;
}

.desc-wrap {
    display: flex;
    align-items: center;

    .this-person {
        // width: 240px;
        white-space: nowrap;
    }
}

.issuer-radios {
    display: flex;
    justify-content: center;

    .form-check {
        
        label{
            font-size: 16px;
        }
    }
}