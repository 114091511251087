@import '../../assets/styles/variables.scss';

.proposed-solution {
    font-size: 18px;
    font-weight: bold;
    padding-top: 10px;
    padding-left: 15px;
    background-color: $color-borders;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .settlement-info {
        text-align: center;
    }

    .settlement-actions {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.keypoints-title {
    padding: 20px 0 15px 15px;
}

.topic-header {
    width: 100%;
    background-color: transparent;
    border: none;
    text-align: left;
    padding: 15px;
    border-bottom: 1px solid gray;
    font-weight: bold;
    border-top: 1px solid gray;
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        cursor: pointer;
        // border-left: 3px solid $color-developers;
        background-color: $color-lighthover !important;
    }
    &:focus {
        outline: none;
    }
}

.comments-wrap {
    padding: 15px;

    .orig-comment-title {
        font-size: 12px;
    }

    .orig-comment {
        font-weight: bold;
        font-style: italic;
    }

    .replies {

        .b-reply {
            font-style: italic;
        }
    }
}

.input-percentage {
    width: 55px;
    border: none;
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
}

.topic-actions {
    display: flex;
}

.resolved {
    color: $color-success;
}

.escalated {
    width: 100%;
    text-align: center;
}