@import '../../assets/styles/variables';

.headline {
    font-size: 18px;
    font-weight: bold;
    color: $color-headlines;
}

.proposal-box {
    margin-left: 0px;
    margin-right: 0px;
    border: 1px solid $color-borders;
    padding: 20px;
}

.proposal-item-box {
    display: flex;
    justify-content: flex-start;
}

.proposal-userinfo {
    display: flex;
}

.proposal-item-body {
    width: 100%;
}

.proposal-item-titles {
    width: 100%;
}

.userinfobar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.name-with-rating {
    width: 100%;
}

.proposal-terms {
    display: flex;
    justify-content: space-between;
}