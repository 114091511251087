@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/mixins.scss';

.jumbo-project-details {
  @include jumbo-cover('/assets/img/floriane-vita.png');
  padding-top: 120px;
  margin-bottom: 0;
}

.p-img {
    background-color: white;
    width: 160px;
    height: 160px;
    border-radius: 2px;
    border: 1px solid $color-borders;
}

.p-header {
    height: 200px;
    margin-top: 26px;
}

.p-first-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 54px;
}

.p-name {
    font-size: 24px;
    font-weight: bold;
    margin-left: 188px;
    color: $color-headlines;
}

.c-header {
    display: flex;
    align-items: center;
}

.c-name {
    font-size: 16px;
    font-weight: bold;
    margin-right: 21px;
    margin-left: 8px;
}

.c-rating {
    font-size: 24px;
    font-weight: bold;
    color: $color-headlines;
}

.p-overview {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.p-overview-title {
    margin-left: 10px;
    font-size: 14px;
    line-height: 20px;
    color: $color-headlines;
}

.p-overview-val {
    font-size: 20px;
    font-weight: bold;
    color: $color-headlines;
}

.p-overview-participants {
    font-size: 14px;
    color: $color-headlines;
    display: flex;
    align-items: center;

    .participant-image {
        width: 35px;
        border: 2px solid white;
        border-radius: 50%;
        height: 35px;
        margin-right: 8px;
    }
}


.p-tabs {
    justify-content: space-around;
    
}

.p-tabs li.active a {
    border: none;
}

.p-tabs .nav-link {
    width: 33%;
    text-align: center;
    font-size: 18px;
    color: $color-headlines;
}

.p-tabs .nav-link.active {
    border: none;
    border-bottom: 3px solid $color-developers;
    background-color: $color-lighthover;
}

.p-tabs .nav-tabs .nav-link:hover {
    border: none !important;
    border-bottom: 3px solid $color-developers !important;
    background-color: $color-lighthover;
    border-radius: 0;
}

.nav-tabs .nav-link {
    border: none !important;
    border-bottom: 3px solid transparent !important;
    color: $color-headlines;
}

.nav-link.active {
    border: none;
    border-bottom: 3px solid $color-developers !important;
}

.tab-content {
    padding-top: 5px;
    padding-left: 48px;
    color: $color-headlines;
    font-size: 14px;
}


.p-tab-content {
    padding-left: 48px;
    width: 70%;
}

.p-content-footer {
    background-color: $color-developers;
    width: 100%;
    height: 106px;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
        color: white;
        font-size: 20px;
        margin-bottom: 11px;
    }
}


.content-title {
    font-size: 16px;
    font-weight: bold;
    color: $color-headlines;
    margin-bottom: 10px;
    margin-top: 30px;
}

.pr1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    height: 35px;
}

.task-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 33px;
}

.task-card {
    width: 359px;
    border: 1px solid $color-borders;
    border-radius: 3px;
    margin-bottom: 16px;
    margin-right: 16px;
    padding: 21px 18px 12px 18px;

    .task-card-title {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: $color-headlines;
    }

    :nth-child(2) {
        margin-top: 11px;
        border-bottom: 1px solid $color-borders;
    }

    &:nth-child(3n) {
        margin-right: 0;
    }

    .task-card-body {
        margin-top: 13px;
        margin-bottom: 12px;
    }

    .card-params {
        margin-top: 15px;
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        :first-child {
            font-size: 24px;
            font-weight: bold;
        }

        :last-child {
            border: none;
        }
    }
}


/* DateTimePicker (AirBnB) */
.DateRangePickerInput__withBorder {
    border-radius: .25rem !important;
    border: 1px solid #ced4da !important;
    width: 100% !important;
}

.DateInput {
    background: transparent !important;
    // width: 47% !important;
    padding-left: 5% !important;
}

.DateInput_input {
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    padding: 6px 11px 4px !important;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: $color-projects !important;
    border: 1px double $color-projects !important;
    color: #fff;
}

.DateInput_input__focused {
    border-bottom: 2px solid $color-projects !important;
}

.CalendarDay__hovered_span {
    background: $color-basic-transparent-100 !important;
    border: 1px double $color-basic-transparent-100 !important;
}

.CalendarDay__selected_span {
    background: $color-basic-transparent-100 !important;
    border: 1px double $color-basic-transparent-100 !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-right: 33px solid $color-projects !important;
}

.visibility-wrapper {
    padding-top: 8px;
}

.browse-milestone {
    font-size: 18px;
}