@import '../../assets/styles/variables.scss';


.hide-section {
    display: none;
}

.delete-role-info {
    display: flex;
    margin-bottom: 24px;
}

.delete-role-details {
    font-weight: bold;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}