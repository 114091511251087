@import '../../assets/styles/variables.scss';


.browse-title {
    font-size: 21px;
    color: $color-headlines;
    font-weight: bold;
    border-left: 3px solid $color-projects;
    padding-left: 15px;
    line-height: 50px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
        height: fit-content;
    }
}

.title-dev {
    border-left: 3px solid $color-developers;
}
.title-inv {
    border-left: 3px solid $color-employers;
}
.title-edu {
    border-left: 3px solid $color-educationals;
}
.title-aud {
    border-left: 3px solid $color-auditors;
}

.browse-search {
    margin-bottom: 15px;
}

.items-count {
    color: $color-headlines;
    font-size: 12px;
    margin-bottom: 10px;
    display: block;
}

.filters-wrapper {
    border: $border;
    border-radius: $border-radius;
    padding: 15px;
}
  
.filters-wrapper > div > h6 {
    display: block;
    font-weight: bold;
    color: $color-headlines;
    border-bottom: 1px solid $color-headlines;
    padding-bottom: 5px;
    font-size: 16px;
}

.filter-title-dev > h6 {
    border-bottom: 1px solid $color-developers !important;
}
.filter-title-inv > h6 {
    border-bottom: 1px solid $color-employers !important;
}
.filter-title-edu > h6 {
    border-bottom: 1px solid $color-educationals !important;
}
.filter-title-aud > h6 {
    border-bottom: 1px solid $color-auditors !important;
}


/* LIST */
.card-list {
    display: flex;
    flex-direction: column;

    :first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        width: 100%;
    }

    > :last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom: 1px solid $color-borders;
    }

}


/* LIST ITEMS */
.card-item {
    padding: 0;
    margin-bottom: 0;
    border-radius: 0;
    border-bottom: none;    

    &:hover {
        background-color: $color-lighthover;
        border-left: 3px solid $color-projects;

        .card-body {
            padding-left: 21px;    
        }
    }

    .card-body {
        padding-top: 21px;
        padding-left: 23px;
        display: flex;
    }

    .card-details {
        width: 100%;
    }

    .card-sep {
        border-left: 1px solid $color-borders;
        margin-left: 24px;
        margin-right: 24px;
    }

    .card-actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 175px;
        text-align: center;
        
        :first-child {
            font-size: 29px;
            font-weight: bold;
        }
    }

    .card-title {
        font-size: 16px;
        font-weight: bold;
        color: $color-headlines;
    }

    .card-desc {
        font-size: 14px;
        white-space: 'pre-wrap';
    }

    .browse-namelink {
        cursor: pointer;
        display: flex;
    }

    .card-info {
        font-size: 14px;
        margin-top: 10px;
        margin-bottom: 10px;
        color: $color-headlines;

        span {
            padding-right: 8px;
        }
    }
}

.card-thumb {
    margin-right: 20px;
}

.p-thumb {
    width: 54px;
    border-radius: 2px;
}

.user-thumb {
    width: 54px;
    height: 54px;
    border-radius: 50%;
}

.card-tags {
    font-size: 14px;
    line-height: 17px;
    color: $color-projects;

    span {
        color: $color-projects;
        background-color: $color-basic-transparent-200;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 3px;
        padding-left: 7px;
        padding-right: 7px;
        margin-right: 8px;
        margin-bottom: 8px;
        display: inline-block;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}

.browse-namelink-freelancer {
    cursor: pointer;
    display: flex;
}

@media (max-width: 767px) { 
    .card-item .card-body {
        flex-direction: column;
    }
}