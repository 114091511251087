@import '../../assets/styles/variables.scss';

.card-h-container {
    display: flex;
}

.mile-item {
    border-bottom: 1px solid $color-borders;

    .ml-item-body {
        display: flex;

        .ml-body-content {
            padding: 10px;

            :first-child {
                font-weight: bold;
                color: $color-headlines;
            }
        
            :nth-child(2) {
                font-size: 14px;
                // color: $color-placeholder;
            }
        
            :nth-child(3) {
                font-size: 14px;
                // color: $color-placeholder;
            }
        }

    }

    &:hover {
        cursor: pointer;
        background-color: $color-lighthover;
    }
}

.delete-milestone {
    font-size: 14px;
    color: $color-placeholder;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}