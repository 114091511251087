@import '../../../assets/styles/variables.scss';

.taskname {
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    color: $color-headlines;
    margin-top: 10px;
}

.trenum-title {
    font-size: 18px;
    font-weight: bold;
    color: $color-headlines;
}

.trenum-body {
    width: 100%;
    margin-top: 10px;
    height: fit-content;

    .trenum-body-col {
        border: 1px solid $color-borders;
        padding: 14px 16px;

        .trenum-title {
            font-size: 16px;
            font-weight: bold;
            color: $color-headlines;
        }

        .trenum-val {
            font-size: 22px;
            font-weight: bold;
            color: $color-developers;
        }
        .trenum-val2 {
            font-size: 16px;
            font-weight: bold;
            color: $color-developers;
        }
    }

    :first-child {
        border-bottom: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    :nth-child(2) {
        border-bottom: none;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .last-trenum {
        border-bottom: 1px solid $color-borders;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .you-like {
        font-size: 16px;
        color: $color-borders;
        margin: 10px;
    }
}

.pinfo {
    font-weight: bold;
}
