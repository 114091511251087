@import '../../../assets/styles/variables.scss';

.new-competence-rodal {
    display: flex;
    align-items: center;
}

.rodal-header-withimg {
    height: 160px;
    background: url('/assets/img/add_a_competency_steps_bg.png');
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.create-c-steps {
    display: flex;
    justify-content: center;
    color: white;
    position: relative;

    .connecting-c-line {
        // height: 2px;
        // background: #e0e0e0;
        border: 1px dotted white;
        position: absolute;
        width: 300px;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 14px;
        z-index: 1;
    }

    .step-c-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 140px;
        z-index: 1;
    
        .step-c-step {
            font-size: 14px;
            font-weight: bold;
            color: $color-background;
            padding-top: 5px;
            background-color: $color-developers;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-bottom: 8px;

            &:hover {
                cursor: pointer;
            }
        }

        .not-available {
            cursor: default !important;
        }

        .step-c-info {
            display: flex;
            align-items: center;
        }

        .step-c-active {
            color: $color-headlines;
            background-color: $color-background;
        }

        :last-child {
            font-size: 14px;
        }

    }
}

.back-arrow-wrap {
    display: flex;
    align-items: center;
    color: $color-developers;
    padding: 0;
    margin-bottom: 12px;
}

.addcompetency-form-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
}

.c-slug {
    font-size: 14px;
    line-height: 30px;
    color: $color-placeholder;
}

.select-level {
    // min-width: 165px;
    width: 100%;
    // margin-right: 12px;
    margin-left: 12px;
}

.desc-wrap {
    display: flex;
    align-items: center;

    .this-person {
        // width: 240px;
        white-space: nowrap;
    }
}

.issuer-radios {
    display: flex;
    justify-content: center;

    .form-check {
        
        label{
            font-size: 16px;
        }
    }
}

.c-validate-warning {
    padding: 4px;
    margin-top: 8px;
    padding-left: 8px;
}

.c-validate-warning .close {
    padding-top: 6px;
    padding-right: 24px;
}

.warning-txt {
    color: $color-headlines;

    a {
        color: $color-headlines;
    }
}
