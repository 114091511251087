@import url('https://fonts.googleapis.com/css?family=Lato');

* {
	box-sizing: border-box;
}

.countdown-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-left: -4px;
}

.countdown-item {
	color: #111;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    line-height: 12px;
	margin: 0;
	margin-top: 9px;
    padding-top: 3px;
    position: relative;
    width: 60px;
    height: 60px;
}

.countdown-item span {
	color: #333;
	// font-size: 14px;
	font-size: 10px;
	font-weight: 600;
	text-transform: uppercase;
}

.countdown-svg {
	position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
}