@import '../../assets/styles/variables.scss';

.project-img-overlay {
    position: relative;
    top: -70px;
    right: -24px;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    cursor: pointer;
  }
  
  .project-img-container:hover .project-img-overlay {
    opacity: 1;
  }

  .project-overview-cover {
    height: 120px;
  }

  .project-overview-coverimg {
    width: 100%;
    height: 100%;
  }

  .project-infobar {
    position: absolute;
    height: 41px;
    width: 100%;
    background-color: $color-developers;
    opacity: 0.8;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 14px;
    padding-right: 14px;
    top: 80px;

    .project-infobar-text {
      font-size: 14px;
    }

  }