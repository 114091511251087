@import '../../assets/styles/variables';

/* TASK PROPOSALS */
.proposal-list {

    .proposal-item {

        .when {
            display: flex;
            justify-content: flex-end;
            padding-top: 10px;
            padding-right: 20px;
            font-size: 12px;
            color: $color-placeholder
        }

        .proposal-item-inner {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid lightgray;
            padding: 20px;
            padding-top: 10px;
            
            // .proposal-thumb {
            //     width: 54px;
            //     margin-right: 15px;
            // }

            .proposal-item-body {

                // .proposal-item-header {
                //     display: flex;
                       
                //     .proposal-item-titles {
            
                //         :first-child {
                //             display: flex;
                //             justify-content: space-between;
            
                //             .p-info-name {
                //                 display: flex;
            
                //                 :nth-child(2) {
                //                     display: flex;
                //                     align-items: baseline;
                //                 }
            
                //                 .p-star {
                //                     font-size: 14px;
                //                     vertical-align: top;
                //                 }
            
                //                 .rating-big {
                //                     font-size: 19px;
                //                     font-weight: bold;
                //                 }
            
                //                 .rating-small {
                //                     font-size: 13px;
                //                 }
                //             }
                //         }
            
                //         :nth-child(2) {
                //             font-size: 12px;
                //             line-height: 18px;
                //             color: $color-text;
                //         }

                //         :nth-child(3) {
                //             margin-top: 5px;
                //         }
                //     }
                // }
            
                .user-competences {
                    margin-top: 10px;
                    margin-bottom: 25px;
                }

                // .proposal-content {
                //     display: flex;
                //     justify-content: space-between;

                //     .proposal-labels {
                //         font-size: 14px;
                //         line-height: 18px;
                //         font-weight: bold;
                //         color: $color-headlines;
                //     }

                //     .proposal-prev {
                //         text-decoration: line-through;
                //         font-size: 14px;
                //         line-height: 18px;
                //         color: $color-placeholder;
                //     }

                //     .proposal-current {
                //         font-size: 24px;
                //         line-height: 18px;
                //         font-weight: bold;
                //         color: $color-developers;
                //     }

                //     .proposal-current-d {
                //         font-size: 18px;
                //         line-height: 18px;
                //         font-weight: bold;
                //         color: $color-developers;
                //     }
                // }
            }

            .v-divider {
                margin-left: 20px;
                margin-right: 20px;
                border-left: 1px solid $color-borders;
            }
        
            .proposal-actions {
                display: flex;
                align-items: center;
                min-width: 120px;
                flex-direction: column;
                justify-content: center;                
            }
        }

    }
}

.tp-labels {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: $color-headlines;
}

.tp-accepted {
    font-weight: bold;
    font-size: 18px;
    color: #23D59B;
}
.tp-rejected {
    font-weight: bold;
    font-size: 18px;
    color: #FF0000;
}

.DateInput {
    width: 106px;
    padding-left: 1% !important;
}