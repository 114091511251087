@import '../variables.scss';

.form-label {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0px;
    color: $color-headlines;
}

.form-group {
    margin-bottom: 10px;
}

.form-check-label {
    font-size: 14px;
}


.form-control {
    // border: 2px solid $color-basic-400;
}

.form-check {
    // padding-top: 20px;
}

.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    color: initial;
}

.custom-checkbox .custom-control-label::before {
    border-radius: 3px;
}

.custom-control-label {
    padding-top: 2px;
    font-size: 14px;
}

.custom-radio {
    padding-top: 20px;
}

// remove once form validations are done correctly
.custom-control-input:checked~.custom-control-label::before {
    color: $color-basic-100;
    border-color: $color-basic-600;
    background-color: $color-basic-600;
}

.was-validated .custom-control-input:valid~.custom-control-label {
    color: initial;
}

.custom-control-input.is-valid~.custom-control-label::before, .was-validated .custom-control-input:valid~.custom-control-label::before {
    border-color: initial;
}

.custom-control-input.is-valid:checked~.custom-control-label::before, .was-validated .custom-control-input:valid:checked~.custom-control-label::before {
    border-color: $color-basic-600;
    background-color: $color-basic-600;
}
