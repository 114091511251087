@import '../../assets/styles/variables';

.tasklist-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid lightgray;
    padding: 20px;
    border-left: 3px solid transparent;

    &:hover {
        border-left: 3px solid $color-headlines;
        background-color: $color-lighthover !important;
    }

    .t-item-body {
        width: 100%;

        .t-item-body-head {

            .t-item-hr1 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                :last-child {
                    font-size: 20px;
                    line-height: 19px;
                    color: $color-headlines;
                    margin-top: 4px;
                }
            }

            .t-item-hr2 {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                :last-child {
                    font-size: 14px;
                    line-height: 18px;
                    color: $color-text;
                }
            }
        }

        .t-item-body-content {

            .t-item-about {
                font-size: 14px;
                line-height: 18px;
                color: $color-text;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            .t-item-footer {

            }
        }

    }

    .v-divider {
        margin-left: 20px;
        margin-right: 20px;
        border-left: 1px solid $color-borders;
    }

    .t-item-actions {
        display: flex;
        align-items: center;
        min-width: 120px;
        flex-direction: column;
        justify-content: center;

        .t-item-noOfproposals {
            padding-bottom: 12px;
        }
    }
}

.drag-handle {
    height: 100px;
    width: 20px;
    background-color: $color-headlines;
    cursor: move;
}

.grip-wrap {
    padding: 2px 12px;
}

span.grippy {
    content: '....';
    width: 14px;
    height: 20px;
    display: inline-block;
    overflow: hidden;
    line-height: 5px;
    cursor: move;
    vertical-align: middle;
    margin-top: -.7em;
    font-size: 18px;
    font-family: sans-serif;
    letter-spacing: 1px;
    color: $color-headlines;
    text-shadow: 1px 0 1px black;
}
span.grippy::after {
    content: '.. .. .. ..';
}