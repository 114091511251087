@import '../../assets/styles/variables';

.edu-card-header {
    display: flex;
    justify-content: space-between;

    .btn-in-header {
        margin-top: 10px;
        margin-right: 10px;
        margin-bottom: 4px;
    }
}

.edu-subtitle {
    font-size: 14px;
    padding-right: 15px;
    padding-left: 15px;
}

.subrole-category {
    font-weight: bold;
    font-size: 14px;
    padding-right: 15px;
    padding-left: 15px;
}

.employee-wrap {
    display: flex;
    align-items: center;
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 4px;
    margin-bottom: 4px;
    
    .employee-name {
        line-height: 36px;
    }

    .delete-subrole {
        display: none;
    }

    &:hover {
        background-color: $color-lighthover;
        .delete-subrole {
            display: inline;
        }
    }
}

.clickable {
    &:hover {
        cursor: pointer;
    }
    &:active {
        background-color: $color-borders;
    }
}

.degree-wrap {
    padding-top: 4px;
    padding-bottom: 4px;
    width: 95%;
    
    .degree-name {
        font-size: 16px;
    }
    .degree-small-name {
        font-size: 14px;
    }
    .degree-desc {
        font-size: 12px;
    }    
}

.delete-icon {
    background-color: transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 7px;
    margin-left: 5px;
    margin-top: -1px;

    &:hover {
      cursor: pointer;
      background-color: $color-borders;
    }
}