@import '../../assets/styles/variables.scss';

.p-uploadfiles-container {
    display: flex;
    flex-direction: column;
}

.p-files-upload {
    display: flex;
    align-items: flex-start;
    border: 2px dashed $color-borders;
    border-radius: 4px;
    padding: 25px;
    color: $color-borders;
    width: 100%;
}

.delete-file{
    width: 18px;
    margin-bottom: 3px;
    &:hover{
        cursor: pointer;
    }
}

.drop-container {
    display: flex;
    align-items: center;
}

.btn-selectfile {
    font-size: smaller;
    margin-right: 20px;
}

.size-exceeds {
    color: $color-danger;
    font-size: 14px;
}