@import '../variables';

.card {
    box-shadow: none;
    border: $border;
    border-radius: $border-radius;
    padding: 26px;
    margin-bottom: 12px;

    .card-head {
        height: 50px;
        border-left: 3px solid $color-developers;
        border-top-left-radius: 3px;
        padding-top: 14px;
        padding-left: 15px;
        font-size: 20px;
        font-weight: bold;
    }
}

.icon-edit {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 16px;
    background-color: transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 7px;
    // color: $color-developers;

    &:hover {
      cursor: pointer;
      background-color: $color-borders;
    }
}

.old-competences {
    font-size: 14px;
    line-height: 17px;
    color: $color-projects;

    span {
        color: $color-placeholder;
        background-color: $color-lighthover;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 3px;
        padding-left: 7px;
        padding-right: 7px;
        margin-right: 8px;
        margin-bottom: 8px;
        display: inline-block;
        text-decoration: line-through;
    }
}
  
