@import '../../assets/styles/variables.scss';

.proposal-container {
    display: flex;
    flex-direction: column;
}

.proposal-content {
    display: flex;
    justify-content: space-around;
}

.proposal-title {
    font-size: 16px;
    font-weight: bold;
    color: $color-headlines;
}

.proposal-invite {

}