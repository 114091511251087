@import '../../assets/styles/variables.scss';
@import '../../assets/styles/mixins.scss';

.final-decision-info {
    font-size: 12px;
    color: $color-text;
}

.dot-c {
    @include draw-circle(150px, $color-employers, $color-basic-1100);
    padding-top: 30px;
    display: inline-block;
}

.dot-f {
    @include draw-circle(150px, $color-developers, $color-basic-1100);
    padding-top: 30px;
    display: inline-block;
}

.licons {
    font-size: 55px;
}

.circle-texts {
    font-size: larger;
    display: inline-block;
    padding-top: 12px;
}

.audit-slider {
    padding-left: 75px;
    padding-right: 75px;
}


.audit-values {
    width: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5px;

    .audit-txt {
        color: $color-developers;
    }
}

.audit-value {
    font-size: 24px;
    font-weight: bold;
    color: $color-developers;
}

.rangeslider__fill {
    background-color: $color-projects !important;
}