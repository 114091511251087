@import '../../../assets/styles/variables.scss';

.finished-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $color-headlines;
    margin-bottom: 41px;
}

.great-job {
    font-size: 41px;
    font-weight: bold;
    color: $color-headlines;
    margin-bottom: 12px;
}

.keep-improving {
    font-size: 16px;
    color: $color-headlines;
    margin-bottom: 37px;
}

.highfive {
    margin-bottom: 31px;
}

.progress-wrap {
    display: flex;
    align-items: center;

    :first-child {
        font-size: 18px;
        margin-right: 15px;
    }
    :last-child {
        font-size: 28px;
        font-weight: bold;
    }
}

.ps-content-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ps-title24 {
    font-size: 24px;
    font-weight: bold;
    color: $color-headlines;
    margin-bottom: 40px;
}