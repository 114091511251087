
@mixin draw-circle($radius, $bgColor, $color) {
    background-color: $bgColor;
    color: $color;
    height: $radius;
    width: $radius;
    border-radius: 50%;
    display: inline-block;
}

@mixin jumbo-cover($img)  {
    background-image: url($img);
    background-size: cover;
    height: 200px;
    border-radius: 0;
  
    h1 {
        font-size: 41px;
        font-weight: bold;
        color: $color-basic-1100;
        z-index: 100;
    }
  
    p {
      color: $color-text;
      font-size: 14px;
    }
}

@mixin dimmed($color1, $color2, $opacity) {
    position: relative;
    &:after {
      content: " ";
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: transparent linear-gradient(180deg, $color1 0%, $color2 100%) 0% 0% no-repeat padding-box;
      opacity: $opacity;
    }
  }