@import '../variables.scss';
@import '../partials/forms';
@import '../partials/buttons';

.auth { 
    max-width: 434px;
    width: 434px;
    background: $color-background;
    border-radius: $border-radius;
    border: $border;
    padding: 45px;
    padding-top: 40px;
    padding-bottom: 35px;
    color: $color-headlines;
}

.auth span { 
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    padding-bottom: 5px;
    color: $color-headlines;
}

.underline {
    border-bottom: 4px solid $color-headlines;
}

.auth form { 
    padding-top: 18px;
}

.auth .form-label {
    color: $color-headlines !important;
}

.auth .btn {
    margin-top: 12px;
    margin-bottom: 20px;
}

.auth .btn img {
    vertical-align: text-bottom;
    margin-right: 10px;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 36px;
    color: $color-placeholder;
}

.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $color-placeholder;
}

.separator::before {
    margin-right: .25em;
}

.separator::after {
    margin-left: .25em;
}

.auth-small > a {
    font-size: 12px;
    color: $color-headlines !important;
    text-decoration: underline;
}


#overlay {
    position:absolute;
    top: 50px;
    left:50%;
    transform:translate(-50%, -50%);
    z-index: 1000;
}