@import '../../assets/styles/variables';

/* TASK PROPOSALS */
.proposal-item-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    
    .proposal-thumb {
        width: 54px;
        margin-right: 15px;
    }

    .proposal-item-body {

        .proposal-item-header {
            display: flex;
                
            .proposal-item-titles {
    
                :first-child {
                    display: flex;
                    // justify-content: space-between;
                    align-items: baseline;
    
                    .p-info-name {
                        display: flex;
                        align-items: flex-start;
                        margin-left: 5px;
                        
                        :nth-child(2) {
                            display: flex;
                            align-items: baseline;
                        }
    
                        .p-star {
                            font-size: 14px;
                            vertical-align: top;
                        }
    
                        .rating-big {
                            font-size: 19px;
                            font-weight: bold;
                        }
    
                        .rating-small {
                            font-size: 13px;
                        }
                    }
                }
    
                :nth-child(2) {
                    font-size: 12px;
                    line-height: 18px;
                    color: $color-text;
                }

                :nth-child(3) {
                    margin-top: 5px;
                }
            }
        }
    
        .user-competences {
            margin-top: 10px;
            margin-bottom: 25px;
        }

        .proposal-content {
            display: flex;
            justify-content: space-between;

            .proposal-labels {
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
                color: $color-headlines;
            }

            .proposal-prev {
                text-decoration: line-through;
                font-size: 14px;
                line-height: 18px;
                color: $color-placeholder;
            }

            .proposal-current {
                font-size: 24px;
                line-height: 18px;
                font-weight: bold;
                color: $color-developers;
            }

            .proposal-current-d {
                font-size: 18px;
                line-height: 18px;
                font-weight: bold;
                color: $color-developers;
            }
        }
    }
}

.added-competences span{
    color: $color-success;
    background-color: $color-success-200;
}

.new-competences{
    display: flex;
}

