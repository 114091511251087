@import './variables';
@import './bootstrap-overrides';
@import './icons';

@import './partials/_buttons';
@import './partials/_cards';
@import './partials/_lists';
@import './partials/_select';
@import './effects/hovers.scss';
@import './effects/animations.scss';


html { overflow-y: scroll; }

body {
  font-family: $font-family-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-background;
  margin: 0 !important;
  padding: 0 !important;
}

h1 {
  font-size: 24px;
  font-weight: bold;
  color: $color-headlines;
  margin-top: 12px;
  margin-bottom: 24px;
}

.body-text {
  font-size: 16px;
  font-weight: normal;
  color: $color-text;
}

.body-small-text {
  font-size: 14px;
  font-weight: normal;
  color: $color-text;
}

code { font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.hidden { display: none !important; }
.shown { display: block !important; }

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer:hover {
  cursor: pointer;
  font-weight: bold;
}

.pt-65 {
  padding-top: 70px;
}

.strong {
  font-weight: bold;
}

/* STATUSes */

.status-circle {
    font-size: 18px;
    margin-right: 5px;
}

.status-color-draft {
  color: $color-status-draft !important;
}

.status-color-published {
  color: $color-status-published !important;
}

.status-color-active {
  color: $color-status-active !important;
}

.status-color-rejected {
  color: $color-status-rejected !important;
}

.status-color-completed {
  color: $color-status-completed !important;
}

.status-color-in_negotiation {
  color: $color-status-negotiating !important;
}

.status-color-in_progress {
  color: $color-status-in-progress !important;
}

.status-color-submitted {
  color: $color-status-submitted !important;
}

.status-color-failed {
  color: $color-status-failed !important;
}

.status-color-pending_audit,
.status-color-in_audit,
.status-color-in_dispute {
  color: $color-status-in-audit !important;
}

.status-color-auditor_failed {
  color: $color-status-audit-failed !important;
}

.status-color-auditor_confirmed {
  color: $color-status-audit-confirmed !important;
}
/* END STATUSes */

/* MILESTONE COLORS */
.m0 {
  background-color: $color-projects;
}
.m1 {
  background-color: $color-developers;
}
.m2 {
  background-color: $color-employers;
}
.m3 {
  background-color: $color-educationals;
}
.m4 {
  background-color: $color-danger;
}
.m5 {
  background-color: $color-success;
}
// colors
.mc0 {
  color: $color-projects;
}
.mc1 {
  color: $color-developers;
}
.mc2 {
  color: $color-employers;
}
.mc3 {
  color: $color-educationals;
}
.mc4 {
  color: $color-danger;
}
.mc5 {
  color: $color-success;
}
/* END MILESTONE COLORS */


/* LEVEL COLORS - Blooms taxonomy */
.level-color-1 {
  background-color: $color-level-1;
}
.level-color-2 {
  background-color: $color-level-2;
}
.level-color-3 {
  background-color: $color-level-3;
}
.level-color-4 {
  background-color: $color-level-4;
}
.level-color-5 {
  background-color: $color-level-5;
}
.level-color-6 {
  background-color: $color-level-6;
}
/* END LEVEL COLORS - Blooms taxonomy */

.form-labels {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  color: $color-headlines;
  margin-bottom: 5px;
}

.caption {
  font-size: 14px;
  color: $color-text;
}

div.ReactTags__suggestions {
  position: absolute;
  z-index: 9999;
}

.label-to-edit:hover {
    cursor: pointer;
    font-style: italic;
}

.label-to-edit-disabled:hover {
  cursor: unset;
  font-style: normal;
}

.file {
  width: 27px;
  height: 37px;  
}
.file-xls, .file-xlsx { background: url('/assets/img/icon/xls.svg') no-repeat center; }
.file-doc, .file-docx { background: url('/assets/img/icon/docx.svg') no-repeat center; }
.file-ppt { background: url('/assets/img/icon/ppt.svg') no-repeat center; }
.file-jpg, .file-JPG { background: url('/assets/img/icon/jpg.svg') no-repeat center; }
.file-png, .file-PNG { background: url('/assets/img/icon/png.svg') no-repeat center; }
.file-pdf { background: url('/assets/img/icon/pdf.svg') no-repeat center; }
.file-zip { background: url('/assets/img/icon/generic_attachment.svg') no-repeat center; }
.file-generic { background: url('/assets/img/icon/generic_attachment.svg') no-repeat center; }

.white {
  color: white !important;
}

.attachments {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 49px;
}

.attachment-box {
  height: 60px;
  width: fit-content;
  margin-right: 16px;
  margin-bottom: 16px;
  border: 1px solid $color-borders;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;

  :last-child {
      margin-left: 16px;
      color: $color-developers;
      font-size: 14px;
  }

  &:hover {
      background-color: $color-lighthover;
  }
}

.download-link:hover {
  cursor: pointer;
}

/* Cover & Avatar common styling */
.sp-cover {
  height: 160px;
  width: 100%;
  // margin-top: 20px;
}

.btn-spchangecover {
  position: absolute;
  z-index: 1;
  margin-top: 15px;
  left: 617px;
}

.sp-avatar-wrapper {
    width: 160px;
    height: 160px;
    margin-top: -80px;
    margin-left: 40px;
    position: absolute;
}

.sp-avatar {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: 2px solid white;
  z-index: 5;
}

.sp-avatar-overlay {
    position: absolute;
    margin-top: -80px;
    display: flex;
    flex-direction: column;
    background-color: #232323;
    opacity: 0.5;
    width: 100%;
    align-items: center;
    font-size: 14px;
    color: white;
    height: 80px;
    padding-top: 20px;
    border-bottom-left-radius: 320px;
    border-bottom-right-radius: 320px;
    z-index: 10;

    &:hover {
        cursor: pointer;
        opacity: 0.7;
    }
}


/*
 *
 * SCROLLBAR STYLING
 *
/

 /* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(185, 185, 185);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(146, 146, 146);
}

/* CSS CLOSE ICON */
.close {
  position: absolute;
  right: 16px;
  top: 11px;
  width: 16px;
  height: 16px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
  cursor: pointer;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 18px;
  width: 1px;
  background-color: white;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}


/* EDIT IN PLACE  */
.easy-edit-component-wrapper {
  width: 100%;
}

/* CSS ARROWS */
.arrow {
  border: solid $color-placeholder;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

// Responsive
@import './breakpoints.scss';