.modal-guts {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.pdf-embeded {
    width: 100%;
    height: 100%;
    overflow : hidden;
}

.docx-embeded {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}