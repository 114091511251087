@import "../../assets/styles/variables.scss";

.msection-rows {
  .msection-row {
    display: flex;
    border-bottom: 1px solid $color-borders;
    padding-bottom: 30px;
    margin-bottom: 30px;
    position: relative;

    &:hover .edit-pencil {
      display: block;
    }
    .edit-pencil {
      position: absolute;
      display: none;
    }

    .edit-icon{
      right: 40px;
      top: 10px;
    }

    .delete-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: transparent;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      padding: 7px;
  
      &:hover {
        cursor: pointer;
        background-color: $color-borders;
      }
    }

    .msec-img {
      width: 54px;
      height: 54px;
      margin-left: 5px;
      margin-right: 25px;
    }

    .msec-info {
      color: $color-headlines;

      :nth-child(1) {
        font-size: 16px;
        font-weight: bold;
      }

      :nth-child(2) {
        font-size: 14px;
        font-weight: normal;
      }

      :nth-child(3) {
        font-size: 14px;
        color: $color-placeholder;
      }
    }
  }

  :last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 5px;
  }
}

.c-section-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.c-group-title {
  font-size: 12px;
  margin-bottom: 5px;
}
