@import '../../assets/styles/variables.scss';

.p-documents {
    display: flex;
    flex-wrap: wrap;
}

.p-adddocs {
    font-size: 14px;
    line-height: 18px;
    color: #008dce;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.easy-edit-not-allowed {
    cursor: unset !important;
}