@import '../../assets/styles/variables';

.milestone-header {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    cursor: pointer;

    .milestone-title {
        // font-weight: bold;
        color: white;
    }

    .milestone-drophere {
        // font-size: 18px;
        // float: left;
    }

    .milestone-hright {

        .marrow {
            border-color: white;
        }
    }
}

.milestone-empty {
    padding: 5px;
}