@import '../../assets/styles/variables.scss';

/* Styles for React-Tag-Input */
.cctags {

}

.cctagInput {
}

.cctagInputField {
  width: 100%;
  padding: 8px 15px;
  border-radius: 3px;
  border: 1px solid $color-placeholder;
  color: $color-placeholder;
  margin-top: 10px;
  font-size: 14px;
}

.ccselected {
}

.cctag {
    border: 1px solid #E8EBF3;
    background-color: #E8EBF3;
    color: #1F3A86;
    font-size: 14px;
    display: inline-block;
    padding: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 5px 5px;
    cursor: move;
    border-radius: 5px;
}

.ccremove {
  margin-left: 5px;
  font-size: 18px;
  line-height: 16px;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

.ccsuggestions {
  position: absolute;
  z-index: 999;
  width: fit-content;
  // padding-right: 40px;
}

.ccsuggestions ul {
  list-style-type: none;
  padding: 0;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 100%;
}

.ccsuggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

.ccsuggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.ccsuggestions ul li.ccactiveSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ccactiveSuggestion {
}


