@import '../../assets/styles/variables';

.log-item {
    border-bottom: 1px solid $color-borders;
    margin-bottom: 24px;
    padding-bottom: 24px;

    .log-title {

    }

    .log-content {
        margin-left: 24px;
        margin-right: 24px;
        margin-top: 24px;
    }
}