@import '../variables';

.btn {
    padding: 6px 40px;
    border: 0 none;
    font-weight: normal;
    cursor: pointer;
}
 
.btn:focus, .btn:active:focus, .btn.active:focus {
    outline: 0 none !important;
}

.btn-default {
    font-size: 14px;
    border-radius: $border-radius;
}

.btn-disabled {
    color: $color-basic-1100;
    background-color: $color-borders;
    border: 1px solid $color-borders;
    cursor: not-allowed;
}
.btn-disabled:hover, .btn-disabled:focus, .btn-disabled:active, .btn-disabled.active, .open > .dropdown-toggle.btn-disabled {
    color: $color-basic-1100;
    background-color: $color-borders;
    box-shadow: none;
}
.btn-disabled:active, .btn-disabled.active {
    color: $color-basic-1100;
    background-color: $color-borders;
    box-shadow: none;
}

/* GENERIC */
.btn-generic {
    color: $color-headlines;
    border: 1px solid $color-headlines;
}
.btn-generic:hover, .btn-generic:focus, .btn-generic:active, .btn-generic.active, .open > .dropdown-toggle.btn-generic {
    color: $color-basic-1100;
    background: $color-headlines;
}
.btn-generic:active, .btn-generic.active {
    color: $color-basic-1100;
    background: $color-headlines;
    box-shadow: none;
}

.btn-smallpadding {
    padding: 6px 20px;
}

.btn-fullWidth {
    width: 100%;
}

/* PROJECTS */
.btn-projects {
    color: $color-projects;
    border: 1px solid $color-projects;
}
.btn-projects:hover, .btn-projects:focus, .btn-projects:active, .btn-projects.active, .open > .dropdown-toggle.btn-projects {
    color: $color-basic-1100;
    background: $color-projects;
}
.btn-projects:active, .btn-projects.active {
    color: $color-basic-1100;
    background: $color-projects;
    box-shadow: none;
}

.btn-projects-inv {
    padding-left: 18px;
    padding-right: 18px;
    background-color: $color-projects;
    border: 1px solid $color-projects;
    color: white;
    &:hover {
        color: white;
    }
}

.btn-p-explore {
    font-size: 14px;
    color: white;
    border: 1px solid white;
    padding-left: 124px;
    padding-right: 124px;
    &:hover {
        color: whitesmoke;
    }
}

/* EMPLOYERS */
.btn-employers {
    color: $color-employers;
    border: 1px solid $color-employers;
}
.btn-employers:hover, .btn-employers:focus, .btn-employers:active, .btn-employers.active, .open > .dropdown-toggle.btn-employers {
    color: $color-basic-1100;
    background: $color-employers;
}
.btn-employers:active, .btn-employers.active {
    color: $color-basic-1100;
    background: $color-employers;
    box-shadow: none;
}
.btn-employers-inv {
    padding-left: 18px;
    padding-right: 18px;
    background-color: $color-employers;
    border: 1px solid $color-employers;
    color: white;
    &:hover {
        color: white;
    }
}

/* DEVELOPERS */
.btn-developers {
    color: $color-developers;
    border: 1px solid $color-developers;
}
.btn-developers:hover, .btn-developers:focus, .btn-developers:active, .btn-developers.active, .open > .dropdown-toggle.btn-developers {
    color: $color-basic-1100;
    background: $color-developers;
}
.btn-developers:active, .btn-developers.active {
    color: $color-basic-1100;
    background: $color-developers;
    box-shadow: none;
}

.btn-developers-inv {
    padding-left: 18px;
    padding-right: 18px;
    background-color: $color-developers;
    border: 1px solid $color-developers;
    color: white;
    &:hover {
        color: white;
    }
}

/* AUDITORS */
.btn-auditors {
    color: $color-auditors;
    border: 1px solid $color-auditors;
}
.btn-auditors:hover, .btn-auditors:focus, .btn-auditors:active, .btn-auditors.active, .open > .dropdown-toggle.btn-auditors {
    color: $color-basic-1100;
    background: $color-auditors;
}
.btn-auditors:active, .btn-auditors.active {
    color: $color-basic-1100;
    background: $color-auditors;
    box-shadow: none;
}
.btn-auditors-inv {
    padding-left: 18px;
    padding-right: 18px;
    background-color: $color-auditors;
    border: 1px solid $color-auditors;
    color: white;
    &:hover {
        color: white;
    }
}

/* EDUCATIONAL INSTITUTIONS */
.btn-educationals {
    color: $color-educationals;
    border: 1px solid $color-educationals;
}
.btn-educationals:hover, .btn-educationals:focus, .btn-educationals:active, .btn-educationals.active, .open > .dropdown-toggle.btn-educationals {
    color: $color-basic-1100;
    background: $color-educationals;
}
.btn-educationals:active, .btn-educationals.active {
    color: $color-basic-1100;
    background: $color-educationals;
    box-shadow: none;
}
.btn-educationals-inv {
    padding-left: 18px;
    padding-right: 18px;
    background-color: $color-educationals;
    border: 1px solid $color-educationals;
    color: white;
    &:hover {
        color: white;
    }
}

/* TRANSPARENT */
.btn-trans {
    font-size: 16px;
    color: $color-headlines;
    border: 1px solid white;
    background: white;
    opacity: 0.8;
}
.btn-trans:hover, .btn-trans:focus, .btn-trans:active, .btn-trans.active, .open > .dropdown-toggle.btn-trans {
    opacity: 0.88;
}
.btn-trans:active, .btn-trans.active {
    opacity: 0.88;
    box-shadow: none;
}

/* INFOBAR */
.btn-infobar {
    font-size: 16px;
    color: $color-headlines;
    border: 1px solid white;
    background: white;
    opacity: 0.8;
}
.btn-infobar:hover, .btn-infobar:focus, .btn-infobar:active, .btn-infobar.active, .open > .dropdown-toggle.btn-infobar {
    opacity: 0.88;
}
.btn-infobar:active, .btn-infobar.active {
    opacity: 0.88;
    box-shadow: none;
}

/* PROJECT SMALL INFOBAR */
.btn-project-infobar {
    font-size: 14px;
    color: white;
    border: 1px solid white;    
}
.btn-project-infobar:hover, .btn-project-infobar:focus, .btn-project-infobar:active, .btn-project-infobar.active, .open > .dropdown-toggle.btn-project-infobar {
    background: white;
    opacity: 0.88;
}
.btn-project-infobar:active, .btn-project-infobar.active {
    background: white;
    opacity: 0.88;
    box-shadow: none;
}

/* Button Success */
.btn-success {
    color: $color-success;
    border: 1px solid $color-success;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
    color: $color-basic-1100;
    background: $color-success;
}
.btn-success:active, .btn-success.active {
    color: $color-basic-1100;
    background: $color-success;
    box-shadow: none;
}

/* Button Danger */
.btn-danger {
    color: $color-danger;
    border: 1px solid $color-danger;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .open > .dropdown-toggle.btn-danger {
    color: $color-basic-1100;
    background: $color-danger;
}
.btn-danger:active, .btn-danger.active {
    color: $color-basic-1100;
    background: $color-danger;
    box-shadow: none;
}