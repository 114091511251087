@import '../../assets/styles/variables';

.user-work-comment {
    margin-left: 42px;
    margin-top: -5px;
    margin-bottom: 15px;
    font-size: 14px;
}
.modal-submit-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 23px;
}

.name-is-working {
    font-weight: bold;
    font-size: 14px;
    color: $color-headlines;
}

.rate-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rate-satisfied {
    font-size: 16px;
    font-weight: bold;
    color: $color-headlines;
    margin-bottom: 15px;
}

.rate-avatar {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 9px;
}

.rate-stars {
    font-size: 27px;
}

.rating-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .rated-user {
        display: flex;
        max-width: 50%;

        .rated-userinfo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;

            .review-user {
                font-size: 16px;
                font-weight: bold;
                color: $color-headlines;
            }
        }
    }

    .rates {
        
        .rate-category{
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
        }

        .rate-categoryname{
            font-size: 14px;
            line-height: 29px;
            margin-right: 10px;
        }
    }
}

